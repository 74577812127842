import PropTypes from 'prop-types';
import classnames from 'classnames';
import I18n from 'i18n-js';

PlayerControl.propTypes = {
    playing: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    onPlay: PropTypes.func,
    onPrevious: PropTypes.func,
    onNext: PropTypes.func,
    onSwitchRandomMode: PropTypes.func,
    onSwitchLoopMode: PropTypes.func,
    modeLoop: PropTypes.bool.isRequired,
    modeRandom: PropTypes.bool.isRequired,
    hasStreamingFeature: PropTypes.bool.isRequired,
};

PlayerControl.defaultProps = {
    playing: false,
    onPlay: null,
    onPrevious: null,
    onNext: null,
    modeLoop: false,
    modeRandom: false,
    hasStreamingFeature: false,
    onSwitchRandomMode: null,
    onSwitchLoopMode: null,
};

export default function PlayerControl({
    playing,
    loading,
    onPlay,
    onPrevious,
    onNext,
    modeLoop,
    modeRandom,
    hasStreamingFeature,
    onSwitchRandomMode,
    onSwitchLoopMode,
}) {
    const className = classnames('action-button', {
        'action-button--loading': loading,
        'action-button--pause': !loading && playing,
        'action-button--play': !loading && !playing,
    });

    return (
        <div className="player__controls">
            <div
                className={hasStreamingFeature === false && 'tooltip'}
                data-tooltip = {hasStreamingFeature === false &&
                    I18n.t('player.forbidden.streaming')}
            >
                <button
                    disabled={hasStreamingFeature === false}
                    onClick={hasStreamingFeature ? onSwitchRandomMode : undefined}
                    className="button-random"
                >
                    <i className={`icon icon--shuffle ${modeRandom ? 'icon--shuffle--active' : ''}`}></i>
                </button>
            </div>
            <div
                className={hasStreamingFeature === false && 'tooltip'}
                data-tooltip = {hasStreamingFeature === false &&
                    I18n.t('player.forbidden.streaming')}
            >
                <button
                    disabled={hasStreamingFeature === false}
                    onClick={hasStreamingFeature ? onSwitchLoopMode : undefined}
                    className="button-loop"
                >
                    <i className={`icon icon--loop ${modeLoop ? 'icon--loop--active' : ''}`}></i>
                </button>
            </div>
            <button disabled={onPrevious === null} onClick={onPrevious}>
                <i className="icon icon--control-prev"></i>
            </button>
            <button disabled={loading || onPlay === null} className={className} onClick={onPlay}>
                <i className="icon"></i>
            </button>
            <button disabled={onNext === null} onClick={onNext}>
                <i className="icon icon--control-next"></i>
            </button>
        </div>
    );
}

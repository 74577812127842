import Container from '@elao/container.js/src/Container';
import { ApolloClient } from '@apollo/client/core';
import createStore from '@App/store';
import History from '@App/service/History';
import AudioApi from '@App/service/AudioApi';
import AuthenticationProvider from '@App/service/AuthenticationProvider';
import MobileWatcher from '@App/service/MobileWatcher';
import Analytics from '@App/service/Analytics';
import Sentry from '@Common/service/Sentry';
import Shortcut from '@App/service/Shortcut';
import SearchInsights from '@App/searchAnalytics/SearchInsights';
import StatsClient from '@App/stats/StatsClient';

const container = new Container();

// Redux Store
container.registerCallback('store', createStore, ['store.initial-state', 'store.enable-logger']);

// Sentry
Sentry.register(container);

// Analytics
container.registerService('analytics', Analytics);

// Services
container.registerService('search_insights', SearchInsights, ['client']);
container.registerService('stats_client', StatsClient, ['client']);
container.registerService('history', History, ['store', 'analytics', 'meta']);
container.registerService('audio', AudioApi);
container.registerService('mobile-watcher', MobileWatcher, ['store']);
container.registerService('shortcut', Shortcut);
container.registerService('authentication', AuthenticationProvider, ['store', 'client', 'sentry']);

// Apollo
container.registerService('client', ApolloClient, ['client.config']);

export const get = container.get;

export default container;

import { Component } from 'react';
import PropTypes from 'prop-types';
import Seeker from '@App/components/ui/Seeker';
import Waveform from '@App/components/model/Waveform';
import { formatDuration } from '@App/utils';

export default class PlayerStatus extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        audioFileUrl: PropTypes.string.isRequired,
        waveform: PropTypes.array.isRequired,
        duration: PropTypes.number.isRequired,
        time: PropTypes.number.isRequired,
        onSeek: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        highlights: PropTypes.arrayOf(PropTypes.shape({
            offset: PropTypes.number.isRequired,
            duration: PropTypes.number.isRequired,
        }))
    };

    renderWaveform() {
        const { id, waveform, duration, time, loading, highlights, audioFileUrl } = this.props;

        if (loading) {
            return <div className="waveform loading" />;
        }

        return <Waveform id={id} audioFileUrl={audioFileUrl} data={waveform} highlights={highlights} duration={duration} progress={time / duration} />;
    }

    render() {
        const { duration, time, onSeek } = this.props;

        return (
            <div className="player__waveform">
                <span>{formatDuration(Math.round(time))}</span>
                <Seeker className="seeker" onSeek={onSeek}>
                    {this.renderWaveform()}
                </Seeker>
                <span>{formatDuration(duration)}</span>
            </div>
        );
    }
}

import {
    OPEN_SELECT_BRIEF_PANEL,
    CLOSE_SELECT_BRIEF_PANEL
} from '@App/store/actions/brief';

const initialState = {
    trackReference: null,
    versionReference: null,
};

export default function brief(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case OPEN_SELECT_BRIEF_PANEL:
            return {
                ...initialState,
                trackReference: payload.trackReference,
                versionReference: payload.versionReference,
            };

        case CLOSE_SELECT_BRIEF_PANEL:
            return {
                ...initialState,
                trackReference: null,
                versionReference: null,
            };

        default:
            return state;
    }
}

import MobileWatcher from '@App/service/MobileWatcher';
import {
    NAVIGATE,
    SET_ANCHOR,
    SET_SIZE,
    FILTER_MODAL_OPEN,
    FILTER_MODAL_CLOSE,
} from '@App/store/actions/navigation';

const initialState = {
    route: null,
    parameters: {},
    query: null,
    anchor: null,
    size: MobileWatcher.getSize(),
    filterModalOpen: false,
};

/**
 * Navigation
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function navigation(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case NAVIGATE:
            return {
                ...state,
                route: payload.route,
                parameters: payload.parameters,
                query: payload.query,
                anchor: payload.anchor,
            };

        case SET_ANCHOR:
            return {
                ...state,
                anchor: payload.anchor,
            };

        case SET_SIZE:
            return {
                ...state,
                size: payload.size,
            };

        case FILTER_MODAL_OPEN:
            return { ...state, filterModalOpen: true };

        case FILTER_MODAL_CLOSE:
            return { ...state, filterModalOpen: false };

        default:
            return state;
    }
}

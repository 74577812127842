import SearchInsightsClick from '@App/api/mutation/searchAnalytics/SearchInsightsClick.graphql';
import SearchInsightsView from '@App/api/mutation/searchAnalytics/SearchInsightsView.graphql';
import SearchInsightsConvert from '@App/api/mutation/searchAnalytics/SearchInsightsConvert.graphql';

export default class SearchInsights {
    constructor(apolloClient) {
        this.apolloClient = apolloClient;
    }

    click(event, objectID, queryID = null, position = null) {
        return this.apolloClient.mutate({
            mutation: SearchInsightsClick,
            variables: { event, objectID, queryID, position },
            context: { tag: 'analytics' }
        });
    }

    view(event, objectID) {
        return this.apolloClient.mutate({
            mutation: SearchInsightsView,
            variables: { event, objectID },
            context: { tag: 'analytics' }
        });
    }

    convert(event, objectID, queryID = null) {
        return this.apolloClient.mutate({
            mutation: SearchInsightsConvert,
            variables: { event, objectID, queryID },
            context: { tag: 'analytics' }
        });
    }

}

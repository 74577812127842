import { Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import authentication from '@App/store/reducers/authentication';
import brief from '@App/store/reducers/brief';
import navigation from '@App/store/reducers/navigation';
import player from '@App/store/reducers/player';
import project from '@App/store/reducers/project';
import track from '@App/store/reducers/track';
import download from '@App/store/reducers/download';
import search from '@App/store/reducers/search';
import searchAnalytics from '@App/store/reducers/searchAnalytics';
import similarity from '@App/store/reducers/similarity';
import { configureStore } from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useSelector} from 'react-redux';

export type AppStore = ReturnType<typeof create>;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch']

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
/**
 * Create store
 *
 * @param  {Object}  initialState
 * @param  {Boolean} enableLog
 *
 * @return {Object}
 */
export default function create(initialState, enableLog = false) {
  const middlewares: Middleware[] = [];

  if (enableLog) {
    middlewares.push(createLogger({ collapsed: true }));
  }

  return configureStore({
    devTools: true,
    reducer: {
      authentication,
      brief,
      navigation,
      player,
      project,
      track,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      download: download as () => any,
      search,
      searchAnalytics,
      similarity,
    } as const,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...middlewares),
    preloadedState: initialState,
  });
}

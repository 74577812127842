export function addUnique(list, value) {
    const set = new Set(list);

    set.add(value);

    return Array.from(set.values());
}

export function removeUnique(list, value) {
    const set = new Set(list);

    set.delete(value);

    return Array.from(set.values());
}

/** Actions names */
export const OPEN_SELECT_BRIEF_PANEL = 'OPEN_SELECT_BRIEF_PANEL';
export const CLOSE_SELECT_BRIEF_PANEL = 'CLOSE_SELECT_BRIEF_PANEL';

export function openSelectBriefPanel(trackReference, versionReference = null) {
    return { type: OPEN_SELECT_BRIEF_PANEL, payload: { trackReference, versionReference } };
}

export function closeSelectBriefPanel() {
    return { type: CLOSE_SELECT_BRIEF_PANEL };
}

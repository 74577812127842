import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { navigate } from '@App/store/actions/navigation';
import { findByName } from '@App/routes';

class Link extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        href: PropTypes.string,
        confirm: PropTypes.string,
        children: PropTypes.node,
        label: PropTypes.string,
        className: PropTypes.string,
        tooltip: PropTypes.bool,
        icon: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        label: null,
        confirm: null,
        className: '',
        tooltip: null,
        icon: null,
    };

    renderIcon(icon) {
        if (!icon) {
            return null;
        }

        return <i className={`icon icon--${icon}`} />;
    }

    renderLabel(label, tooltip) {
        if (tooltip) {
            return <span className="sr-only">{label}</span>;
        }

        return label;
    }

    render() {
        const { href, onClick, className, tooltip, label, icon, children, ...props } = this.props;

        props.className = classnames(className, { tooltip });

        if (tooltip) {
            props['data-tooltip'] = label;
        }

        if (!href) {
            return (
                <button onClick={onClick} type="button" {...props}>
                    {this.renderIcon(icon)}
                    {this.renderLabel(label, tooltip)}
                    {children}
                </button>
            );
        }

        return (
            <a href={href} onClick={onClick} {...props}>
                {this.renderIcon(icon)}
                {this.renderLabel(label, tooltip)}
                {children}
            </a>
        );
    }
}

/**
 * @param {String} routeName
 * @param {Object} parameters
 * @param {String|null} anchor
 *
 * @return {String}
 */
function getUrl(routeName, parameters = {}, anchor = null) {
    const route = findByName(routeName);
    if (!route) {
        throw new Error(`Route "${routeName}" not found.`);
    }

    return route.getUrl(parameters).concat(anchor ? `#${anchor}` : '');
}

const Wrapper = connect(
    null,
    (dispatch, props) => ({
        onClick: event => {
            // Prevent browser navigation.
            event.preventDefault();

            // Confirm before navigate
            if (props.confirm && !confirm(props.confirm)) {
                return;
            }

            // Navigate through redux store
            dispatch(navigate(props.route, props.parameters, props.anchor));

            if (typeof props.onUse === 'function') {
                props.onUse();
            }
        },
    }),
    (stateProps, dispatchProps, ownProps) => {
        const { noFollow, route, parameters, anchor, ...props } = ownProps;

        delete props.onUse;

        return {
            ...props,
            ...stateProps,
            ...dispatchProps,
            href: noFollow ? null : getUrl(route, parameters, anchor),
        };
    }
)(Link);

Wrapper.propTypes = {
    route: PropTypes.string.isRequired,
    parameters: PropTypes.object,
    anchor: PropTypes.string,
    onUse: PropTypes.func,
    noFollow: PropTypes.bool,
};

Wrapper.defaultProps = {
    parameters: {},
    anchor: null,
    onUse: null,
    noFollow: false,
};

export default Wrapper;

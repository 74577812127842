import {
    SIMILARITY_SET_URL, SIMILARITY_SET_FILE, SIMILARITY_SET_TRACK, SIMILARITY_CLEAR, TOGGLE_SUPPRESS_VOCALS,
    TOGGLE_PRIORITIZE_BPM, SIMILARITY_SET_TIME,
} from '@App/store/actions/similarity';
import {
    ADD_TO_INCLUDED_TAGS,
    REMOVE_FROM_INCLUDED_TAGS,
    ADD_TO_EXCLUDED_TAGS,
    REMOVE_FROM_EXCLUDED_TAGS,
    SET_DURATION,
    REMOVE_DURATION,
    ADD_TO_LABELS,
    REMOVE_FROM_LABELS,
    ADD_TERM,
} from '@App/store/actions/search';
import {addUnique, removeUnique} from '@App/store/reducers/utils/reducer-helpers';

const initialState = {
    url: null,
    track: null,
    file: null,
    includedTags: [],
    excludedTags: [],
    labels: [],
    minDuration: null,
    maxDuration: null,
    offset: null,
    limit: null,
    suppressVocals: false,
    prioritizeBpm: false,
};

// UTILS FONCTIONS

/**
 * Is currently searching?
 *
 * @param  {Object}  state
 *
 * @return {Boolean}
 */
export function isSearching(state) {
    const { url, track, file } = state.similarity;

    return url !== null
        || track !== null
        || file !== null;
}

/**
 * Search
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function similarity(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SIMILARITY_SET_URL:
            return { ...initialState, url: payload.url };

        case SIMILARITY_SET_FILE:
            return { ...initialState, file: true };

        case SIMILARITY_SET_TRACK:
            return { ...initialState, track: payload.track };

        case SIMILARITY_CLEAR:
            return initialState;

        case SET_DURATION:
            return { ...state, minDuration: payload.min, maxDuration: payload.max };

        case SIMILARITY_SET_TIME:
            return { ...state, offset: payload.offset, limit: payload.limit };

        case REMOVE_DURATION:
            return { ...state, minDuration: null, maxDuration: null };

        case TOGGLE_SUPPRESS_VOCALS:
            return { ...state, suppressVocals: !state.suppressVocals };

        case TOGGLE_PRIORITIZE_BPM:
            return { ...state, prioritizeBpm: !state.prioritizeBpm };

        case ADD_TO_INCLUDED_TAGS:
            return {
                ...state,
                includedTags: addUnique(state.includedTags, payload.id),
                excludedTags: removeUnique(state.excludedTags, payload.id),
            };

        case REMOVE_FROM_INCLUDED_TAGS:
            return {
                ...state,
                includedTags: removeUnique(state.includedTags, payload.id),
            };

        case ADD_TO_EXCLUDED_TAGS:
            return {
                ...state,
                includedTags: removeUnique(state.includedTags, payload.id),
                excludedTags: addUnique(state.excludedTags, payload.id),
            };

        case REMOVE_FROM_EXCLUDED_TAGS:
            return {
                ...state,
                excludedTags: removeUnique(state.excludedTags, payload.id),
            };

        case ADD_TO_LABELS:
            return { ...state, labels: addUnique(state.labels, payload.id) };

        case REMOVE_FROM_LABELS:
            return { ...state, labels: removeUnique(state.labels, payload.id) };

        case ADD_TERM:
            // Clear similarity search when a standard search is performed.
            return initialState;

        default:
            return state;
    }
}

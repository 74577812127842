import {
    PLAY_TRACK,
    SEEK_TRACK,
    SET_PLAYLIST,
    SWITCH_LOOP_MODE,
    SWITCH_RANDOM_MODE,
    TRACK_PAUSED,
    TRACK_PLAYING
} from '@App/store/actions/player';

const PLAY_STATE = {
    LOADING: 'LOADING',
    PLAYING: 'PLAYING',
    PAUSED: 'PAUSED',
};

const initialState = {
    trackReference: null,
    versionReference: null,
    startPosition: null,
    playlist: null,
    modeLoop: false,
    modeRandom: false,
    randomPlaylist: null,
    /**
     * If the active track is actually loading, playing or paused
     *
     * @type {PLAY_STATE}
     **/
    playState: PLAY_STATE.LOADING,
    history: [],
};

/**
 * Is player active?
 *
 * @param  {Object}  state Redux store
 *
 * @return {Boolean}
 */
export function isPlayerActive(state) {
    return state.player.trackReference !== null;
}

/**
 * Get next track in playlist
 *
 * @param  {Object} state Redux store
 *
 * @return {Object} Object with keys: trackReference, versionReference, highlights.
 */
export function getNextTrack(state) {
    let nextTrack = getTrack(state, 1);

    if (null === nextTrack && state.player.modeLoop) {
        nextTrack = getFirstTrack(state);
    }

    return nextTrack;
}

/**
 * Get previous track in playlist
 *
 * @param  {Object} state Redux store
 *
 * @return {Object} Object with keys: trackReference, versionReference, highlights.
 */
export function getPreviousTrack(state) {
    return getTrack(state, -1);
}

export function isPlayingTrack(state, trackReference) {
    return state.player.trackReference === trackReference && state.player.playState === PLAY_STATE.PLAYING;
}

export function isPausedTrack(state, trackReference) {
    return state.player.trackReference === trackReference && state.player.playState === PLAY_STATE.PAUSED;
}

export function isLoadingTrack(state, trackReference) {
    return state.player.trackReference === trackReference && state.player.playState === PLAY_STATE.LOADING;
}

export function isCurrentTrack(state, trackReference) {
    return state.player.trackReference === trackReference;
}

function getTrack(state, step = 1) {
    const { playlist, randomPlaylist, modeRandom, trackReference, versionReference } = state.player;

    const currentPlaylist = modeRandom ? randomPlaylist : playlist;
    if (currentPlaylist === null) {
        return null;
    }

    const index = currentPlaylist.findIndex(element => element.trackReference === trackReference && element.versionReference === versionReference);

    if (index < 0) {
        return null;
    }

    if (typeof currentPlaylist[index + step] === 'undefined') {
        return null;
    }

    return currentPlaylist[index + step];
}

function getFirstTrack(state) {
    const { playlist, randomPlaylist, modeRandom } = state.player;

    const currentPlaylist = modeRandom ? randomPlaylist : playlist;
    if (currentPlaylist === null) {
        return null;
    }

    return currentPlaylist[0];
}

function shuffle(statePlaylist, trackReference, versionReference) {
    let playlist = [...statePlaylist];
    let currentIndex = playlist.length,  randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [playlist[currentIndex], playlist[randomIndex]] = [playlist[randomIndex], playlist[currentIndex]];
    }
    // Move current track to the top of the playlist
    const index = playlist.findIndex(element => element.trackReference === trackReference
        && element.versionReference === versionReference);

    playlist.splice(index, 1);
    playlist.unshift({ trackReference, versionReference });

    return playlist;
}

/**
 * Audio player
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function player(state = initialState, action) {
    const { type, payload } = action;
    const MAX_HISTORY_SIZE = 50;

    switch (type) {
        case PLAY_TRACK:
            return {
                ...state,
                trackReference: payload.trackReference,
                versionReference: payload.versionReference,
                startPosition: payload.startPosition,
                playState: PLAY_STATE.LOADING,
                highlights: payload.highlights,
                history: state.history.slice(-(MAX_HISTORY_SIZE - 1)).concat([ payload.versionReference ]),
            };

        case SEEK_TRACK:
            return {
                ...state,
                startPosition: payload.offset,
            };

        case SWITCH_LOOP_MODE: {
            const modeLoop = !state.modeLoop;
            return {
                ...state,
                modeLoop: modeLoop,
            };
        }

        case SWITCH_RANDOM_MODE: {
            const modeRandom =  !state.modeRandom;
            const randomPlaylist = modeRandom ? shuffle(
                state.playlist,
                state.trackReference,
                state.versionReference
            ) : null;

            return {
                ...state,
                modeRandom: modeRandom,
                randomPlaylist: randomPlaylist,
            };
        }

        case TRACK_PLAYING:
            return { ...state, playState: PLAY_STATE.PLAYING };

        case TRACK_PAUSED:
            return { ...state, playState: PLAY_STATE.PAUSED };

        case SET_PLAYLIST:
            return { ...state, playlist: payload.playlist };

        default:
            return state;
    }
}

/** Actions names */
export const DOWNLOAD_TRACK = 'DOWNLOAD_TRACK';
export const DOWNLOAD_PROJECT = 'DOWNLOAD_PROJECT';
export const DOWNLOAD_PLAYLIST = 'DOWNLOAD_PLAYLIST';
export const CLOSE_DOWNLOAD = 'CLOSE_DOWNLOAD';

/** Actions */

/**
 * Show the download panel for the given track
 *
 * @param  {String} trackReference
 * @param  {String|null} versionReference
 *
 * @return {Object}
 */
export function downloadTrack(trackReference, versionReference = null) {
    return { type: DOWNLOAD_TRACK, payload: { trackReference, versionReference } };
}

/**
 * Show the download panel for the given project
 *
 * @param  {String} projectId
 *
 * @return {Object}
 */
export function downloadProject(projectId) {
    return { type: DOWNLOAD_PROJECT, payload: { projectId } };
}

/**
 * Show the download panel for the given playlist
 *
 * @param  {String} playlistId
 *
 * @return {Object}
 */
export function downloadPlaylist(playlistId) {
    return { type: DOWNLOAD_PLAYLIST, payload: { playlistId } };
}

/**
 * Close track details
 *
 * @return {Object}
 */
export function closeDownloadPanel() {
    return { type: CLOSE_DOWNLOAD };
}

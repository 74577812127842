import { createElement } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import QueryContent from '@App/components/api/QueryContent';

/**
 * Query the API and map its result to the given component
 */
export default function Query(props) {
    const {
        query,
        variables,
        component,
        mapResult,
        mapLoading,
        mapError,
        childProps,
        loadingComponent,
        errorComponent,
        loadMore,
        onCompleted,
        pollInterval,
        shouldStopPolling,
    } = props;
    const { loading, error, data, fetchMore, startPolling, stopPolling } = useQuery(query, { variables, onCompleted, pollInterval });

    return createElement(
        QueryContent,
        Object.assign(
            {
                loading,
                error,
                data,
                component,
                mapResult,
                mapLoading,
                mapError,
                childProps,
                loadingComponent,
                errorComponent,
                startPolling,
                stopPolling,
                shouldStopPolling,
            },
            loadMore ? { onLoadMore: () => onLoadMore(fetchMore, query, variables, data, loadMore) } : {},
        )
    );
}

/**
 * Load more callback
 *
 * @see https://www.apollographql.com/docs/react/pagination/cursor-based/#relay-style-cursor-pagination
 *
 * @param  {Function} fetchMore
 * @param  {Query} query
 * @param  {Object} variables
 * @param  {Object} data
 * @param  {String} queryName
 */
function onLoadMore(fetchMore, query, variables, data, queryName) {
    const { pageInfo } = data[queryName];

    if (pageInfo.hasNextPage) {
        return fetchMore({
            variables: {
                ...variables,
                cursor: pageInfo.endCursor,
            },
        });
    }

    return Promise.resolve();
}

Query.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    query: PropTypes.object.isRequired,
    variables: PropTypes.object,
    /** A callable with (data) args called on success and returning components props. */
    mapResult: PropTypes.func,
    /** A callable called on loading and returning components props. */
    mapLoading: PropTypes.func,
    /** A callable with (error, data) args called on error and returning components props. */
    mapError: PropTypes.func,
    childProps: PropTypes.object,
    loadingComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    errorComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    /**
     * (data: TData | {}) => void
     *
     * A callback executed once your query successfully completes.
     *
     * @see https://www.apollographql.com/docs/react/data/queries/#options
     */
    onCompleted: PropTypes.func,
    loadMore: PropTypes.string,
    pollInterval: PropTypes.number,
    shouldStopPolling: PropTypes.func,
};

Query.defaultProps = {
    variables: {},
    mapResult: null,
    mapLoading: null,
    mapError: null,
    childProps: {},
    loadingComponent: undefined,
    errorComponent: undefined,
    loadMore: null,
    pollInterval: 0,
    shouldStopPolling: () => false,
    onCompleted: () => {},
};

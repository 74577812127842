import {
    SEARCH_ANALYTICS_CLICK,
    SEARCH_ANALYTICS_VIEW,
    SEARCH_ANALYTICS_CONVERT,
} from '@App/store/actions/searchAnalytics';
import { addUnique } from '@App/store/reducers/utils/reducer-helpers';

const initialState = {
    clicks: [],
    views: [],
    conversions: [],
};

/**
 * Search analytics
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function track(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SEARCH_ANALYTICS_CLICK:
            return {
                ...state,
                clicks: addUnique(state.clicks, (`${payload.event}#${payload.objectID}#${payload.queryID}`)),
            };

        case SEARCH_ANALYTICS_VIEW:
            return {
                ...state,
                views: addUnique(state.views, `${payload.objectID}#${payload.objectID}`),
            };

        case SEARCH_ANALYTICS_CONVERT:
            return {
                ...state,
                conversions: addUnique(state.conversions, `${payload.event}#${payload.objectID}#${payload.queryID}`),
            };

        default:
            return state;
    }
}

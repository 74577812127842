/** Actions names */
export const ADD_TO_INCLUDED_TAGS = 'ADD_TO_INCLUDED_TAGS';
export const REMOVE_FROM_INCLUDED_TAGS = 'REMOVE_FROM_INCLUDED_TAGS';
export const ADD_TO_EXCLUDED_TAGS = 'ADD_TO_EXCLUDED_TAGS';
export const REMOVE_FROM_EXCLUDED_TAGS = 'REMOVE_FROM_EXCLUDED_TAGS';
export const ADD_TERM = 'ADD_TERM';
export const REMOVE_TERM = 'REMOVE_TERM';
export const SET_DURATION = 'SET_DURATION';
export const REMOVE_DURATION = 'REMOVE_DURATION';
export const SAVE_SEARCH_TRACKS_QUERY_ID = 'SAVE_SEARCH_TRACKS_QUERY_ID';
export const SHOW_TAGS = 'SHOW_TAGS';
export const ADD_TO_LABELS = 'ADD_TO_LABELS';
export const REMOVE_FROM_LABELS = 'REMOVE_FROM_LABELS';

/** Actions */

export function saveSearchTracksQueryID(queryID) {
    return { type: SAVE_SEARCH_TRACKS_QUERY_ID, payload: { queryID } };
}

export function addToIncludedTags(id) {
    return { type: ADD_TO_INCLUDED_TAGS, payload: { id } };
}

export function removeFromIncludedTags(id) {
    return { type: REMOVE_FROM_INCLUDED_TAGS, payload: { id } };
}

export function addToExcludedTags(id) {
    return { type: ADD_TO_EXCLUDED_TAGS, payload: { id } };
}

export function removeFromExcludedTags(id) {
    return { type: REMOVE_FROM_EXCLUDED_TAGS, payload: { id } };
}

export function addTerm(term) {
    return { type: ADD_TERM, payload: { term } };
}

export function removeTerm(term) {
    return { type: REMOVE_TERM, payload: { term } };
}

export function setDuration(min = null, max = null) {
    return { type: SET_DURATION, payload: { min, max } };
}

export function removeDuration() {
    return { type: REMOVE_DURATION };
}

export function showTags(value) {
    return { type: SHOW_TAGS, payload: { showTags: value } };
}

export function addToLabels(id) {
    return { type: ADD_TO_LABELS, payload: { id } };
}

export function removeFromLabels(id) {
    return { type: REMOVE_FROM_LABELS, payload: { id } };
}

import StatsTrackPlayed from '@App/api/mutation/stats/StatsTrackPlayed.graphql';

export default class StatsClient
{
    constructor(apolloClient) {
        this.apolloClient = apolloClient;
    }

    trackPlayed(reference, streaming) {
        return this.apolloClient.mutate({
            mutation: StatsTrackPlayed,
            variables: { reference, streaming },
        });
    }
}

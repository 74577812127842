import React from 'react';

interface Props {
  onSeek: (position: number) => void
  children: React.ReactNode
  startCursorX?: number
  endCursorX?: number
  duration?: number
}

export default function Seeker({
  onSeek,
  duration,
  children,
  startCursorX,
  endCursorX,
  ...props
}: Props) {
  function onClick(event) {
    const {currentTarget, clientX} = event;
    const {offsetWidth} = currentTarget;
    const offsetLeft = currentTarget.getBoundingClientRect().left;
    const position = (clientX - offsetLeft) / offsetWidth;

    if (!isClickOnCursors(event, startCursorX, endCursorX, duration)) {
      onSeek(position);
    }

    function isClickOnCursors(event: React.MouseEvent<HTMLDivElement>, startCursorX?: number, endCursorX?: number, duration?: number): boolean {
      if (startCursorX === undefined || endCursorX === undefined || duration === undefined) {
        return false;
      }

      const { currentTarget, clientX } = event;
      const { left } = currentTarget.getBoundingClientRect();
      const positionInPixels = clientX - left;

      return positionInPixels >= startCursorX && positionInPixels <= endCursorX;
    }
  }

  return <div onClick={onClick} {...props}>{children}</div>;
}


import {
    SHOW_TRACK_VERSIONS,
    CLOSE_TRACK_VERSIONS,
    SHOW_TRACK_INFORMATIONS,
    CLOSE_TRACK_INFORMATIONS,
} from '@App/store/actions/track';

export const PANEL_INFORMATIONS = 'panel-informations';
export const PANEL_VERSIONS = 'panel-versions';

const initialState = {
    panel: null,
    trackReference: null,
    versionReference: null,
};

/**
 * Track
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function track(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SHOW_TRACK_VERSIONS:
            return {
                ...state,
                panel: PANEL_VERSIONS,
                trackReference: payload.trackReference,
            };

        case SHOW_TRACK_INFORMATIONS:
            return {
                ...state,
                panel: PANEL_INFORMATIONS,
                trackReference: payload.trackReference,
                versionReference: payload.versionReference,
            };

        case CLOSE_TRACK_VERSIONS:
        case CLOSE_TRACK_INFORMATIONS:
            return initialState;

        default:
            return state;
    }
}

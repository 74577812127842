import GetMe from '@App/api/query/GetMe.graphql';
import { setUser, setAnonymous } from '@App/store/actions/authentication';

export default class AuthenticationProvider {
    constructor(store, client, sentry) {
        this.store = store;
        this.client = client;
        this.sentry = sentry;

        this.onResult = this.onResult.bind(this);
    }

    init() {
        this.client.query({ query: GetMe, errorPolicy: 'all' }).then(this.onResult);
    }

    onResult(result) {
        const { loading, error, data } = result;
        const { authentication } = this.store.getState();
        let identificationEmail = '';
        let identificationToken = '';

        if (!loading) {
            if (typeof data === 'object' && data.me) {
                const { id, email, firstName, lastName, type, permissions, hubspotConversationToken } = data.me;
                identificationEmail = email;
                identificationToken = hubspotConversationToken;

                if (id !== authentication.id) {
                    this.store.dispatch(setUser(id, { firstName, lastName, type }, permissions));
                    this.sentry.setUser(id, email);
                }
            } else {
                if (authentication.id !== null) {
                    // Anonymous
                    this.store.dispatch(setAnonymous());
                }

                if (error) {
                    this.sentry.captureException(error);
                }
            }

            this.showHubspotWidget(identificationEmail, identificationToken);
        }
    }

    showHubspotWidget(identificationEmail, identificationToken) {
        if (typeof window === 'undefined') {
            return;
        }

        window.hsConversationsSettings = {
            identificationEmail,
            identificationToken
        };

        if (window.HubSpotConversations !== undefined) {
            window.HubSpotConversations.widget.load();
        }
    }
}

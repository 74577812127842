/** Actions names */
export const SHOW_TRACK_VERSIONS = 'SHOW_TRACK_VERSIONS';
export const CLOSE_TRACK_VERSIONS = 'CLOSE_TRACK_VERSIONS';
export const SHOW_TRACK_INFORMATIONS = 'SHOW_TRACK_INFORMATIONS';
export const CLOSE_TRACK_INFORMATIONS = 'CLOSE_TRACK_INFORMATIONS';

/** Actions */

/**
 * Show the given track versions
 *
 * @param  {String} trackReference
 *
 * @return {Object}
 */
export function showVersionsPanel(trackReference) {
    return { type: SHOW_TRACK_VERSIONS, payload: { trackReference } };
}

/**
 * Close track versions
 *
 * @return {Object}
 */
export function closeVersionsPanel() {
    return { type: CLOSE_TRACK_VERSIONS };
}

/**
 * Show the given track information
 *
 * @param {String} trackReference
 * @param {String} versionReference
 *
 * @return {Object}
 */
export function showInformationsPanel(trackReference, versionReference = null) {
    return { type: SHOW_TRACK_INFORMATIONS, payload: { trackReference, versionReference } };
}

/**
 * Close track information
 *
 * @return {Object}
 */
export function closeInformationsPanel() {
    return { type: CLOSE_TRACK_INFORMATIONS };
}

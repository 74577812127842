/** Actions names */
export const OPEN_SELECT_PROJECT_PANEL = 'OPEN_SELECT_PROJECT_PANEL';
export const CLOSE_SELECT_PROJECT_PANEL = 'CLOSE_SELECT_PROJECT_PANEL';

export function openSelectProjectPanel(
    trackReference,
    versionReference = null,
    searchResultsPosition = null
) {
    return {
        type: OPEN_SELECT_PROJECT_PANEL, payload:
        {
            trackReference,
            versionReference,
            searchResultsPosition
        }
    };
}

export function closeSelectProjectPanel() {
    return { type: CLOSE_SELECT_PROJECT_PANEL };
}

import { CLOSE_DOWNLOAD, DOWNLOAD_PROJECT, DOWNLOAD_PLAYLIST, DOWNLOAD_TRACK } from '@App/store/actions/download';

const initialState = {
    trackReference: null,
    versionReference: null,
    projectId: null,
    type: null,
    id: null,
    status: null,
    url: null
};

export const TYPE_TRACK = 'track';
export const TYPE_PROJECT = 'project';
export const TYPE_PLAYLIST = 'playlist';

/**
 * Download
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function download(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DOWNLOAD_TRACK:
            return {
                ...initialState,
                trackReference: payload.trackReference,
                versionReference: payload.versionReference,
                type: TYPE_TRACK,
            };

        case DOWNLOAD_PROJECT:
            return {
                ...initialState,
                projectId: payload.projectId,
                type: TYPE_PROJECT,
            };
        case DOWNLOAD_PLAYLIST:
            return {
                ...initialState,
                playlistId: payload.playlistId,
                type: TYPE_PLAYLIST,
            };

        case CLOSE_DOWNLOAD:
            return initialState;

        default:
            return state;
    }
}

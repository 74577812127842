/** Actions names */
export const PLAY_TRACK = 'PLAY_TRACK';
export const SEEK_TRACK = 'SEEK_TRACK';
export const SET_PLAYLIST = 'SET_PLAYLIST';
export const TRACK_PLAYING = 'TRACK_PLAYING';
export const TRACK_PAUSED = 'TRACK_PAUSED';

export const TRACK_LISTENING = 'TRACK_LISTENING';
export const SWITCH_RANDOM_MODE = 'SWITCH_RANDOM_MODE';
export const SWITCH_LOOP_MODE = 'SWITCH_LOOP_MODE';

/** Actions */

/**
 * Load the given track
 *
 * @param  {String} trackReference
 * @param  {String|null} versionReference
 * @param  {Number|null} startPosition
 * @param  {{offset: Number, duration: Number}[]} highlights
 *
 * @return {Object}
 */
export function playTrack(trackReference, versionReference = null, startPosition = null, highlights = []) {
    return { type: PLAY_TRACK, payload: { trackReference, versionReference, startPosition, highlights } };
}

/**
 * Seek the currently playing track to the given position
 *
 * @param  {Number|null} offset
 *
 * @return {Object}
 */
export function seekTrack(offset = null) {
    return { type: SEEK_TRACK, payload: { offset } };
}

/**
 * Play current track
 *
 * @return {Object}
 */
export function play() {
    return { type: TRACK_PLAYING };
}

/**
 * Pause the current track
 *
 * @return {Object}
 */
export function pause() {
    return { type: TRACK_PAUSED };
}

/**
 * Set current playlist
 *
 * @param {Array|null} playlist
 */
export function setPlaylist(playlist = null) {
    return { type: SET_PLAYLIST, payload: { playlist } };
}

/**
 * Switch on/off random mode
 *
 * @return {Object}
 */
export function switchRandomMode() {
    return { type: SWITCH_RANDOM_MODE };
}

/**
* Switch on/off loop mode
*
* @return {Object}
*/
export function switchLoopMode() {
    return { type: SWITCH_LOOP_MODE };
}

export default class Analytics {
    static DisabledClient() {}

    constructor() {
        this.client = typeof gtag === 'function' ? gtag : this.constructor.DisabledClient;
        this.trackingId = typeof GOOGLE_ANALYTICS_ID === 'string' ? GOOGLE_ANALYTICS_ID : null;
    }

    /**
     * Send page view
     *
     * @param {String} page_path The path portion of a URL. This value should start with a slash (/) character.
     * @param {String} page_title The page's title.
     */
    page(page_path = document.location.pathname, page_title = document.title) {
        this.client('config', this.trackingId, { page_title, page_path });
    }

    /**
     * Send event
     *
     * @param  {String} name
     * @param  {Object} params
     */
    event(name, params = {}) {
        this.client('event', name, params);
    }
}

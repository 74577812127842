import { LIBRARY } from '@App/routes';

/** Actions names */

export const NAVIGATE = 'NAVIGATE';
export const SET_ANCHOR = 'SET_ANCHOR';
export const SET_SIZE = 'SET_SIZE';
export const FILTER_MODAL_OPEN = 'FILTER_MODAL_OPEN';
export const FILTER_MODAL_CLOSE = 'FILTER_MODAL_CLOSE';

/** Actions */

export function home() {
    return navigate(LIBRARY);
}

/**
 * Navigate to the given route
 *
 * @param  {String} route
 * @param  {Object} parameters
 * @param  {String} anchor
 * @param  {Object?} query
 *
 * @return {Object}
 */
export function navigate(route, parameters = {}, anchor = null, query = null) {
    return { type: NAVIGATE, payload: { route, parameters, anchor, query } };
}

export function setAnchor(anchor = null) {
    return { type: SET_ANCHOR, payload: { anchor } };
}

/**
 * Set display size
 *
 * @param  {Number} size
 *
 * @return {Object}
 */
export function setSize(size) {
    return { type: SET_SIZE, payload: { size } };
}

/**
 * Open filter modal
 *
 * @return {Object}
 */
export function openFilterModal() {
    return { type: FILTER_MODAL_OPEN };
}

/**
 * Close filter modal
 *
 * @return {Object}
 */
export function closeFilterModal() {
    return { type: FILTER_MODAL_CLOSE };
}

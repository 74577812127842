import {
    OPEN_SELECT_PROJECT_PANEL,
    CLOSE_SELECT_PROJECT_PANEL
} from '@App/store/actions/project';

const initialState = {
    trackReference: null,
    versionReference: null,
    searchResultsPosition: null,
};

export default function project(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case OPEN_SELECT_PROJECT_PANEL:

            return {
                ...initialState,
                trackReference: payload.trackReference,
                versionReference: payload.versionReference,
                searchResultsPosition: payload.searchResultsPosition,
            };

        case CLOSE_SELECT_PROJECT_PANEL:
            return {
                ...initialState,
                trackReference: null,
                versionReference: null,
                searchResultsPosition: null,
            };

        default:
            return state;
    }
}

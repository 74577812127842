/** Actions names */
export const SET_USER = 'SET_USER';
export const SET_ANONYMOUS = 'SET_ANONYMOUS';
export const OPEN_SIMULTANEOUS_LOGINS_SPOTTED_PANEL = 'OPEN_SIMULTANEOUS_LOGINS_SPOTTED_PANEL';
export const CLOSE_SIMULTANEOUS_LOGINS_SPOTTED_PANEL = 'CLOSE_SIMULTANEOUS_LOGINS_SPOTTED_PANEL';

/** Actions */

/**
 * Set current user
 *
 * @param {String} id
 * @param {Object} profile
 * @param {Object} permissions
 */
export function setUser(id, profile, permissions) {
    return { type: SET_USER, payload: { id,  profile, permissions } };
}

/**
 * Set current user
 *
 * @param {OBject|null} user
 */
export function setAnonymous() {
    return { type: SET_ANONYMOUS };
}

export function openSimultaneousLoginsSpottedPanel() {
    return { type: OPEN_SIMULTANEOUS_LOGINS_SPOTTED_PANEL };
}

export function closeSimultaneousLoginsSpottedPanel() {
    return { type: CLOSE_SIMULTANEOUS_LOGINS_SPOTTED_PANEL };
}

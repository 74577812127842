import { get } from '@App/container';

/* Actions names */

export const SEARCH_ANALYTICS_CLICK = 'SEARCH_ANALYTICS_CLICK';
export const SEARCH_ANALYTICS_VIEW = 'SEARCH_ANALYTICS_VIEW';
export const SEARCH_ANALYTICS_CONVERT = 'SEARCH_ANALYTICS_CONVERT';

/* Actions */

export function click(event, objectID, queryID = null, position = null) {

    const searchInsights = get('search_insights');

    searchInsights.click(event, objectID, queryID, position);

    return { type: SEARCH_ANALYTICS_CLICK, payload: { event, objectID, queryID, position } };
}

export function view(event, objectID) {

    const searchInsights = get('search_insights');

    searchInsights.view(event, objectID);

    return { type: SEARCH_ANALYTICS_VIEW, payload: { event, objectID } };
}

export function convert(event, objectID, queryID = null) {

    const searchInsights = get('search_insights');

    searchInsights.convert(event, objectID, queryID);

    return { type: SEARCH_ANALYTICS_CONVERT, payload: { event, objectID, queryID } };
}

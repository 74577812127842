import { setSize } from '@App/store/actions/navigation';

export default class MobileWatcher {
    static MOBILE_THRESHOLD = 992;

    static SIZE_XS = 0;
    static SIZE_SM = 576;
    static SIZE_MD = 768;
    static SIZE_LG = 992;
    static SIZE_XL = 1120;
    static SIZE_XXL = 1350;

    static SIZES = [
        MobileWatcher.SIZE_XS,
        MobileWatcher.SIZE_SM,
        MobileWatcher.SIZE_MD,
        MobileWatcher.SIZE_LG,
        MobileWatcher.SIZE_XL,
        MobileWatcher.SIZE_XXL,
    ];

    /**
     * @param {Object} store Redux store
     */
    constructor(store) {
        this.store = store;
        this.size = null;

        this.onResize = this.onResize.bind(this);

        window.addEventListener('resize', this.onResize);
        window.addEventListener('load', this.onResize);
    }

    static getSize() {
        if (typeof window === 'undefined'){
            return SIZE_XXL;
        }

        const clientWidth = window.innerWidth;

        return this.SIZES.reduce(
            (maxWidth, width) => clientWidth > width ? width : maxWidth
        );
    }

    /**
     * On resize
     */
    onResize() {
        const size = this.constructor.getSize();

        if (size !== this.size) {
            this.size = size;
            this.store.dispatch(setSize(size));
        }
    }
}

export const SIZE_XS = MobileWatcher.SIZE_XS;
export const SIZE_SM = MobileWatcher.SIZE_SM;
export const SIZE_MD = MobileWatcher.SIZE_MD;
export const SIZE_LG = MobileWatcher.SIZE_LG;
export const SIZE_XXL = MobileWatcher.SIZE_XXL;

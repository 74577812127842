import { createElement, Component } from 'react';
import PropTypes from 'prop-types';
import { NOT_FOUND, ACCESS_DENIED } from '@App/api/errors';
import NotFound from '@App/components/page/error/NotFound';
import AccessDenied from '@App/components/page/error/AccessDenied';
import Internal from '@App/components/page/error/Internal';
import Loader from '@App/components/ui/Loader';

export default class QueryContent extends Component {
    static propTypes = {
        component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
        loading: PropTypes.bool.isRequired,
        data: PropTypes.object,
        error: PropTypes.object,
        mapResult: PropTypes.func,
        mapLoading: PropTypes.func,
        mapError: PropTypes.func,
        childProps: PropTypes.object,
        loadingComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
        errorComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
        onLoadMore: PropTypes.func,
        shouldStopPolling: PropTypes.func,
        stopPolling: PropTypes.func,
    };

    static defaultProps = {
        data: null,
        error: null,
        map: null,
        childProps: {},
        loadingComponent: Loader,
        errorComponent: null,
        onLoadMore: null,
        shouldStopPolling: null,
        stopPolling: null,
    };

    shouldComponentUpdate(nextProps) {
        // Don't re-render component when another data is loading.
        if (nextProps.loading && this.props.data !== null) {
            return false;
        }

        return true;
    }

    getErrorComponent(error, customErrorComponent = null) {
        if (customErrorComponent) {
            return customErrorComponent;
        }

        if (error.graphQLErrors.some(graphQLError => graphQLError.code === NOT_FOUND)) {
            return NotFound;
        }

        if (error.graphQLErrors.some(graphQLError => graphQLError.code === ACCESS_DENIED)) {
            return AccessDenied;
        }

        return Internal;
    }

    render() {
        const {
            loading,
            data,
            error,
            mapResult,
            mapLoading,
            mapError,
            component,
            loadingComponent,
            errorComponent,
            childProps,
            onLoadMore,
            shouldStopPolling,
            stopPolling,
        } = this.props;

        if (loading) {
            const loadingProps = typeof mapLoading === 'function' ? mapLoading() : { loading };

            return createElement(loadingComponent, { ...childProps, ...loadingProps, onLoadMore });
        }

        if (error) {
            const errorProps = typeof mapError === 'function' ? mapError(error, data) : { error, data };

            if (shouldStopPolling && shouldStopPolling(errorProps)) {
                stopPolling();
            }

            return createElement(
                this.getErrorComponent(error, errorComponent),
                { ...childProps, ...errorProps, onLoadMore }
            );
        }

        const resultProps = data === null ? {} : (typeof mapResult === 'function' ? mapResult(data) : { data });

        if (shouldStopPolling && shouldStopPolling(resultProps)) {
            stopPolling();
        }

        return createElement(component, { ...childProps, ...resultProps, onLoadMore });
    }
}

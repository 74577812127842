import { navigate, setAnchor } from '@App/store/actions/navigation';
import { findByName, findByUrl } from '@App/routes';
import NotFoundError from '@App/error/NotFoundError';

export default class History {
    /**
     * @param {Object} store Redux store
     * @param {Analytics} analytics Google analytics
     * @param {AbstractMetaHandler} Meta handler
     */
    constructor(store, analytics, meta) {
        this.store = store;
        this.analytics = analytics;
        this.meta = meta;

        this.onStoreChange = this.onStoreChange.bind(this);
        this.onBack = this.onBack.bind(this);
        this.loadUrl = this.loadUrl.bind(this);

        if (typeof window !== 'undefined') {
            this.unsubscribe = this.store.subscribe(this.onStoreChange);
            window.addEventListener('popstate', this.onBack);
        }
    }

    /**
     * Load url from window location
     *
     * @param {String} url
     * @param {String} hash
     * @param {Bool} failOnNotFound
     */
    loadUrl(url, hash = null, failOnNotFound = false) {
        const urlObject = new URL(url);

        const { route, parameters } = this.getRoute(urlObject.pathname, failOnNotFound);
        const anchor = hash ? hash.slice(1) : null;
        const { navigation } = this.store.getState();

        if (route !== navigation.route) {
            this.store.dispatch(navigate(route, parameters, anchor, Object.fromEntries(urlObject.searchParams.entries())));
        } else if (anchor !== navigation.anchor) {
            this.store.dispatch(setAnchor(anchor));
        }

        const canonical = this.getUrl(route, parameters);

        // Update metadata canonical url:
        if (canonical) {
            this.meta.url = this.meta.getAbsoluteUrl(canonical);
        }
    }

    /**
     * On browser history back
     */
    onBack() {
        this.loadUrl(window.location.toString(), window.location.hash);
    }

    /**
     * Feed browser history on redux store change
     */
    onStoreChange() {
        const { navigation } = this.store.getState();
        const { state } = window.history;
        const url = this.getUrl(navigation.route, navigation.parameters, navigation.anchor, navigation.query);

        if (!state || url !== this.getUrl(state.route, state.parameters, state.anchor, state.query)) {
            // Add a new item in browser history.
            history.pushState(navigation, null, url);
            this.analytics.page();
        }

        if (url) {
            // Update metadata canonical url:
            this.meta.url = this.meta.getAbsoluteUrl(url);
        }
    }

    /**
     * Get url for the given route and parameters
     *
     * @param {String} route
     * @param {Object|null} parameters
     * @param {Object|null} anchor
     *
     * @return {String|null}
     */
    getUrl(route, parameters = null, anchor = null, queryParams = null) {
        const current = findByName(route);

        if (!current) {
            if (typeof parameters === 'object' && typeof parameters.url !== 'undefined') {
                return parameters.url;
            }

            return null;
        }

        return current.getUrl(parameters, queryParams).concat(anchor ? `#${anchor}` : '');
    }

    /**
     * Get route from the given url
     *
     * @param {String} url
     * @param {Bool} failOnNotFound
     *
     * @return {Object}
     */
    getRoute(url, failOnNotFound = false) {
        const current = findByUrl(url);

        if (!current) {
            if (failOnNotFound) {
                throw new NotFoundError(`No route found matching url "${url}".`);
            }

            return { route: 'not-found', parameters: { url } };
        }

        return {
            route: current.name,
            parameters: current.getParameters(url),
        };
    }
}

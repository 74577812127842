/** Actions names */
export const SIMILARITY_SET_URL = 'SIMILARITY_SET_URL';
export const SIMILARITY_SET_FILE = 'SIMILARITY_SET_FILE';
export const SIMILARITY_SET_TRACK = 'SIMILARITY_SET_TRACK';
export const SIMILARITY_CLEAR = 'SIMILARITY_CLEAR';
export const TOGGLE_SUPPRESS_VOCALS = 'TOGGLE_SUPPRESS_VOCALS';
export const TOGGLE_PRIORITIZE_BPM = 'TOGGLE_PRIORITIZE_BPM';
export const SIMILARITY_SET_TIME = 'SIMILARITY_SET_OFFSET';

/** Actions */

export function setUrl(url) {
    return { type: SIMILARITY_SET_URL, payload: { url } };
}

export function setFile() {
    return { type: SIMILARITY_SET_FILE };
}

export function setTrack(track) {
    return { type: SIMILARITY_SET_TRACK, payload: { track } };
}

export function clear() {
    return { type: SIMILARITY_CLEAR };
}

export function toggleSuppressVocals() {
    return { type: TOGGLE_SUPPRESS_VOCALS };
}

export function togglePrioritizeBpm() {
    return { type:  TOGGLE_PRIORITIZE_BPM };
}
export function setTime(offset = null, limit = null) {
    return { type: SIMILARITY_SET_TIME, payload: { offset, limit } };
}

export default class AppError extends Error {
    constructor(message, data) {
        super(message);

        this.data = data;
    }

    get code() {
        return this.constructor.CODE;
    }
}

import EventEmitter from 'tom32i-event-emitter.js';

export default class Shortcut extends EventEmitter {
    constructor() {
        super();

        this.onKey = this.onKey.bind(this);

        this.start();
    }

    start() {
        if (typeof document !== 'undefined') {
            document.addEventListener('keyup', this.onKey);
        }
    }

    stop() {
        if (typeof document !== 'undefined') {
            document.addEventListener('keyup', this.onKey);
        }
    }

    onKey(event) {
        switch (event.keyCode) {
            case 27:
                this.emit('escape');
                break;
        }
    }
}

/**
 * Format duration to format: MM:SS
 *
 * @param {Number} duration
 *
 * @return {String}
 */
export function formatDuration(duration) {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);

    return `${minutes}:${seconds.toString().padStart(2, 0)}`;
}

/**
 * Format duration to format: XminY / Ysec
 *
 * @param  {Number} duration
 *
 * @return {String}
 */
export function formatDurationWord(duration) {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;

    if (minutes) {
        return `${minutes} min ${seconds ? seconds : ''}`;
    }

    return `${seconds} s`;
}

import { Component } from 'react';
import I18n from 'i18n-js';
import Link from '@App/components/navigation/Link';
import { LIBRARY } from '@App/routes';
import background from '@Image/not-found.jpg';

export default class NotFound extends Component {
    render() {
        return (
            <div className="content">
                <header className="header">
                    <div className="header__background">
                        <div className="background" style={{ background: `url(${background})` }}></div>
                    </div>
                    <div className="header__content container">
                        <ul className="breadcrumb">
                            <li className="breadcrumb__item">
                                <Link route={LIBRARY.name}>
                                    {I18n.t('breadcrumb.home')}
                                </Link>
                            </li>
                            <li className="breadcrumb__item">
                                <a href="#">
                                    {I18n.t('breadcrumb.not_found')}
                                </a>
                            </li>
                        </ul>
                        <h1>{I18n.t('page.not_found.title')}</h1>
                        <div className="description">
                            <p>{I18n.t('page.not_found.message')}</p>
                            <Link className="btn btn--light btn--large" route={LIBRARY.name}>
                                {I18n.t('page.not_found.exit')}
                            </Link>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}
